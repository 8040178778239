import { Appointment } from "../models";

export const appointment: Appointment = {
  doctor: null,
  client: null,
  start_at: null,
  end_at: null,
  // incident_at: null,
  default_place: null,
  procedure_code: null,
  procedure_codes: [
    {
      id: null,
      modifier_a: null,
      modifier_b: null,
      units: 1,
      charge: null,
      payout: null,
      max_charge: null
    }
  ],
  diagnoses: [],
  // is_insurance: false,
  is_driver_fee: false,
  insurance: null,
  // units: null,
  // charge: '',
  // copay: '',
  // max_charge: '',
  // balance_due: '',
  // is_patient_signature: true,
  // patient_signed_at: null,
  // is_insured_signature: true,
  is_provider_signature: true,
  // provider_signed_at: new Date(),
  provider_submitted_at: null,
  ally_submitted_at: null,
  cancelled_at: null,
  cancel_reason: '',
  status: null,
  type: 1
}