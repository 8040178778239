import { Client } from '../models/client'
import { contact } from './contact'
export const client: Client = {
  main: {
    account_number: '',
    status: '',
    inactive_reason: null,
    is_authorized: false,
    first_name: '',
    last_name: '',
    middle_initial: '',
    suffix: '',
    nickname: '',
    birthday_at: null,
    age: '',
    sex: '',
    years_old: '',
    gender_identity: '',
    intake_forms_sent_at: null,
    intake_forms_completed_at: null,
    languages: [],
    language: 'english',
    doctors: [],
    street: '',
    city: '',
    state: '',
    zip_code: '',
    phone: '',
    email: '',
    first_seen_at: null,
    last_seen_at: null,
    next_appointment_at: null,
    notes: '',
    action_needed: '',
    url_action_needed: '',
    due_action_needed: null
  },
  billing_insurance_info: {
    // privateOrInsurance: '',
    is_insurance: false,
    is_credit: false,
    insurance_type: 'Primary Insurance',
    insurance_payer: '',
    client_relationship_insured: '',
    other_client_relationship_insured: '',
    first_name: '',
    last_name: '',
    middle_initial: '',
    street: '',
    city: '',
    state: '',
    zip_code: '',
    birthday_at: null,
    sex: '',
    member_id: '',
    policy_group_number: '',
    plan_name: '',
    copay_amount: '',
    // deductible_amount: '',
    deductible_used: '',
    deductible_used_updated_at: null,
    visit_limit: '',
    is_no_visit_limit: false,
    // disabled
    visits_used: '',
    visits_used_updated_at: null,
    phone: '',
    coinsurance: 0,
    other_coinsurance: 0,
    insurance_start_at: null,
    insurance_end_at: null,
    // front_insurance_card: '',
    // back_insurance_card: '',
    is_covered_other_plan: false,
    is_covered_additional_plan: false,
    other_insurance_type: 'Secondary Insurance',
    additional_insurance_type: 'Other',
    is_related_employment: false,
    is_related_auto_accident: false,
    auto_accident_state: '',
    is_related_other_accident: false,
    unable_work_from: null,
    unable_work_to: null,
    diagnoses: [],
    // default_diagnosis_pointer: [],
    is_outside_lab_charges: false,
    outside_lab_fees: '',
    default_place: '',
    // place_code: '',
    procedure_code: null,
    number_of_units: 1,
    // rate_per_unit: '',
    name_referring_provider: '',
    referring_provider_id: '',
    npi_referring_provider: '',
    prior_authorization_number: '',
    // prior_authorization_attachment: '',
    prior_authorization_start: null,
    prior_authorization_end: null,
    insurance_note: null,
    other_first_name: '',
    additional_first_name: '',
    other_last_name: '',
    additional_last_name: '',
    other_middle_initial: '',
    additional_middle_initial: '',
    other_street: '',
    additional_street: '',
    other_city: '',
    additional_city: '',
    other_state: '',
    additional_state: '',
    other_zip_code: '',
    additional_zip_code: '',
    other_birthday_at: null,
    additional_birthday_at: null,
    other_sex: '',
    additional_sex: '',
    additional_insurance_payer_name: '',
    other_insurance_payer: '',
    additional_insurance_payer: '',
    additional_insurance_payer_type: '',
    other_member_id: '',
    additional_member_id: '',
    other_policy_group_number: '',
    additional_policy_group_number: '',
    other_copay_amount: '',
    additional_copay_amount: '',
    // other_deductible_amount: '',
    // additional_deductible_amount: '',
    other_deductible_used: '',
    additional_deductible_used: '',
    other_deductible_used_updated_at: null,
    additional_deductible_used_updated_at: null,
    other_visit_limit: '',
    other_is_no_visit_limit: false,
    additional_visit_limit: '',
    additional_is_no_visit_limit: false,
    // disabled
    other_visits_used: '',
    additional_visits_used: '',
    other_visits_used_updated_at: null,
    additional_visits_used_updated_at: null,
    other_phone: '',
    additional_phone: '',
    other_insurance_start_at: null,
    other_insurance_end_at: null,
    // other_front_insurance_card: '',
    // other_back_insurance_card: '',
    // additional_front_insurance_card: '',
    // additional_back_insurance_card: ''
    insurance_histories: [],
    stripe_id: '',
    virtual_coverage: 'not-sure',
    is_driver_fee_allowed: true,
  },
  contacts: [],
  issues: [],
  documents: [],
}